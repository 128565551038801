@import "../_abstracts.scss";

.footer {
  $footer-height: 25rem;
  position: relative;
  background-color: var(--color-tertiary);
  height: $footer-height;
  display: flex;
  align-items: center;
  color: white;

  &__image {
    position: absolute;
    right: 0;
    top: 0;
    height: calc(#{$footer-height} - 3rem);

    @include respond(phone) {
      height: 15rem;
    }
  }

  span {
    font-size: 1.6rem;

    @include respond(phone) {
      width: 65%;
    }
  }
}

.footer.container {
  padding: 0 9rem;

  @include respond(phone) {
    padding: 0 4rem;
  }
}
