@import "../abstracts";

.private-offer {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  /*@include blueCover(
      "../static/pictures/private-offer-background.png",
      rgba($background-tertiary, 0.9)
    )*/
  @include respond(laptop) {
    padding: 12rem 0;
  }

  @include respond(tab-land) {
    padding: 5rem 0;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-around;

    @include respond(laptop) {
      display: block;
    }
  }

  &__left-container {
    width: 40%;

    @include respond(laptop) {
      width: 100%;
    }
  }

  &__title {
    @include respond(laptop) {
      text-align: center;
    }
  }

  &__text {
    padding: 1rem 0 5rem;

    @include respond(tab-land) {
      text-align: justify;
      padding: 2rem 0 3rem;
    }
  }

  .vimeo-player {
    @include respond(laptop) {
      margin: 0 auto;

      &__iframe {
        margin: 0 50%;
        transform: translateX(-50%);
      }
    }
  }

  .button {
    @include respond(laptop) {
      margin: 0 50%;
      transform: translateX(-50%);
    }

    @include respond(phone-land) {
      width: max-content !important;
    }
  }
}
