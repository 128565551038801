@import "../_abstracts.scss";

.button {
  width: 150px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  padding: 1.5rem 4rem;

  @include respond(phone-land) {
    //width: max-content !important;
    font-size: 2rem;
  }

  &:hover {
    background-color: white;
    transform: translateY(-5px);
    transition: all 0.2s ease;
    cursor: pointer;
  }
}
