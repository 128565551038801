@import "../abstracts";

.language-selector {
  display: flex;
  height: min-content;
  margin-top: 0.6rem;
  margin-left: auto;
  margin-right: 4rem;

  &__flag-div {
    padding: 2rem 0.5rem;
  }

  &__image {
    width: 7rem;
    height: 4rem;
    cursor: pointer;
  }

  @include respond(tab-land) {
    position: absolute;
    bottom: 2rem;
    right: 0;
  }

  @include respond(tab-port) {
    top: 0;
    height: 80px;
    display: flex;
    align-items: center;
    z-index: 500;
    //bottom: 0;
  }
}

.langugage-selector__start-button {
  display: flex;
  width: max-content;
  height: max-content;
  padding: 2rem;
  //border-radius: 50%;
  border: 0;
  outline: none;
  margin-left: auto;
  margin-right: 5rem;
  margin-top: 2rem;
  background-color: var(--color-secondary);
  color: white;
  font-weight: 200;
  cursor: pointer;

  @include respond(tab-land) {
    position: absolute;
    bottom: 2rem;
    right: 0;
  }

  @include respond(tab-port) {
    top: 0;
    height: 80px;
    display: flex;
    align-items: center;
    z-index: 500;
    right: 20px;
  }

  @include respond(phone-land) {
    right: -10px;
  }
  @include respond(phone) {
    right: -30px;
  }
}
