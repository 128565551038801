@import "../abstracts";

.pricing .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  height: 67rem;
  padding: 2rem;
  width: 40rem;

  &:not(:last-child) {
    margin-right: 4rem;
  }

  @include respond(laptop) {
    height: 71rem;
  }

  @include respond(tab-land) {
    height: 75rem;
  }

  @include respond(phone) {
    width: 80%;
    height: max-content;
    flex-direction: column;
    margin: 4rem 0 0 0;

    &:not(:last-child) {
      margin: 4rem 0 0 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__play {
    display: none;
  }

  &__crown {
    position: absolute;
    transform: translateY(-11.2rem);
    width: 10rem;
    filter: invert(66%) sepia(93%) saturate(1435%) hue-rotate(10deg)
      brightness(101%) contrast(101%);
  }

  &__features-list {
    @include respond(tab-land) {
      padding: 0 1rem 2rem;
    }
  }

  .button {
    @include respond(phone-land) {
      font-size: 2rem;
    }
  }

  &:last-child {
    transform: translateY(-5rem);
    background-color: var(--color-secondary);
    color: white;

    @include respond(laptop) {
      transform: translateY(7rem);
    }

    .button:hover {
      background-color: #f0f0f0 !important;
      color: grey;
    }

    .card {
      &__price-container {
        border: 1px solid white;
        background-color: white;
      }

      &__price {
        color: var(--color-secondary);
      }

      &__feature {
        color: white;
      }
    }
  }

  &:not(:last-child) {
    //margin-right: 20px;
    background-color: var(--background-tertiary);

    .card__title {
      color: var(--color-tertiary);
    }

    .card__feature-container {
      img {
        filter: invert(52%) sepia(72%) saturate(4914%) hue-rotate(221deg)
          brightness(93%) contrast(103%);
      }
    }

    .card__crown {
      display: none;
    }
  }

  &__title {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 2rem;
  }

  &__price-container {
    border: 1px solid var(--color-secondary);
    width: 13rem;
    height: 13rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0 2rem;
  }

  &__price {
    color: var(--color-tertiary);
    font-size: 4rem;
    font-weight: 200;
  }

  &__features-container {
    width: 100%;
  }

  &__feature-container {
    display: flex;

    img {
      transform: translateY(4px);
      width: 2.2rem;
      padding-right: 1rem;
    }
  }

  &__feature {
    list-style: none;
    font-size: 1.6rem;
    padding: 0.5rem;
    padding-left: 0;
    color: var(--color-tertiary);
    width: 100%;
  }

  .button {
    margin-top: auto;
    margin-bottom: 2rem;
    width: 100%;
  }
}
