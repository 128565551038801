.VideoPlayer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  width: 100vw;
  height: 100vh;

  &__iframe {
    border: 0;
    width: 70%;
  }

  &__back-icon {
    position: absolute;
    top: 3rem;
    left: 3rem;
    width: 5rem;
    filter: invert(96%) sepia(0%) saturate(1055%) hue-rotate(223deg)
      brightness(30%) contrast(90%);

    &:hover {
      cursor: pointer;
      transform: scale(1.3);
      transition: transform 0.3s ease-in-out;
    }
  }
}
