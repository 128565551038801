@import "../_abstracts.scss";

.tools {
  position: relative;
  z-index: 0;
  display: flex;
  background-image: url("../../static/pictures/tools-background.png");
  background-size: cover;
  justify-content: center;

  @include respond(vertical-800) {
    padding: 10rem 0;
  }

  @include respond(tab-land) {
    padding: 6rem 0;
  }

  @include respond(phone) {
    padding: 3rem 0 6rem;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-around;

    @include respond(laptop) {
      justify-content: center;
    }
  }

  .vimeo-player {
    margin-bottom: 4rem;
    &__iframe {
      @include respond(laptop) {
        margin: 0 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__left-container {
    width: 40%;

    @include respond(laptop) {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: 3rem;

    @include respond(laptop) {
      text-align: center;
    }
    @include respond(phone) {
      margin-bottom: 5rem;
    }
  }

  &__text {
    padding: 0.8rem 0;

    @include respond(laptop) {
      padding: 0;
      font-size: 1.55rem;
    }

    @include respond(phone-land) {
      font-size: 2rem;
    }
  }

  .button {
    margin-top: 1.3rem;

    @include respond(phone-land) {
      width: max-content !important;
    }
  }
}
