@import "../abstracts";

.laptop {
  background-color: var(--main-white);
  height: min-content;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include respond(phone) {
      padding: 0 3.5rem;
    }
  }

  &__title {
    margin: 6rem 0 4rem;
    width: 35vw;

    @include respond(laptop) {
      margin: 2rem 0 4rem;
      width: 50vw;
    }

    @include respond(tab-land) {
      width: 70vw;
    }
    @include respond(phone) {
      width: 100%;
    }
  }

  &__text {
    text-align: left;
    width: 35vw;
    margin: 0 auto;
    padding: 1rem 0;

    @include respond(laptop) {
      width: 45vw;
    }

    @include respond(tab-land) {
      width: 60vw;
    }
    @include respond(phone) {
      width: 100%;
    }
  }

  &__image-container {
    position: relative;
  }

  &__image {
    width: 40vw;
    margin: 5rem auto 12rem;

    @include respond(laptop) {
      margin: 5rem auto;
      width: 50vw;
    }

    @include respond(tab-land) {
      width: 58vw;
    }

    @include respond(phone-land) {
      margin: 4rem auto 0;
    }

    @include respond(phone-land) {
      width: 100%;
    }
  }
}
