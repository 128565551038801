@import "./abstracts";

.container {
  padding: 0 15rem;
  height: 100%;

  @include respond(phone) {
    padding: 0 3rem;
  }
}

.blue {
  color: var(--color-tertiary);
}

.dark-blue {
  color: var(--color-secondary);
  font-weight: 200;
}

.bold {
  font-weight: 700;
}

.tab-land-d-none {
  @include respond(tab-land) {
    display: none !important;
  }
}

.tab-port-d-none {
  @include respond(tab-port) {
    display: none !important;
  }
}

.laptop-d-none {
  @include respond(laptop) {
    display: none !important;
  }
}

.desktop-d-none {
  @include respond(big-desktop) {
    display: none !important;
  }
}

.laptop-up-d-none {
  @include respond(tabland-up) {
    display: none !important;
  }
}

.tabport-up-d-none {
  @include respond(tabport-up) {
    display: none !important;
  }
}

.video-player {
  text-align: center;
}

.typed-cursor {
  color: var(--color-secondary);
  font-weight: 300;
}
