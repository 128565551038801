@import "../_abstracts.scss";

.vimeo-player {
  //display: flex;
  //justify-content: center;
  &__iframe {
    width: 580px;
    height: 326.25px;

    @include respond(4k) {
      width: 1000px;
      height: 562.5px;
    }

    @include respond(phone) {
      width: 350px;
      height: 196.875px;
    }
  }
}

/*
<iframe src="https://player.vimeo.com/video/395021283" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
*/
