@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700|Poppins:300,400,700,800&display=swap");
@import "./abstracts";

:root {
  --main-white: #f3f3f3;
  --color-secondary: #6066f2;
  --color-tertiary: #00b0ff;
  --background-tertiary: #f2fafc;
}

html {
  font-size: 10px;

  @include respond(4k) {
    font-size: 20px;
  }
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  height: 90vh;

  @include respond(laptop) {
    height: min-content;
    padding: 5rem 0 10rem;
  }
  @include respond(phone) {
    height: min-content;
    padding: 5rem 0 10rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 2.5rem;

  @include respond(phone-land) {
    font-size: 3rem;
  }

  @include respond(phone) {
    font-size: 2.5rem;
  }
}

p {
  font-size: 1.6rem;
  line-height: 1.8;

  @include respond(phone-land) {
    font-size: 2rem;
  }
}

button {
  @include respond(4k) {
    font-size: 2rem;
  }
}

a {
  text-decoration: none;
  color: white;

  &:hover {
    color: darken($color: #ffffff, $amount: 12);
    transition: color 0.2s ease;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
