@import "../abstracts.scss";

.top {
  @include blueCover($top-background-image, rgba(#00b0ff, 0.9));
  //height: 90vh;
  padding: 0;
  height: 75rem;

  @include respond(laptop) {
    height: max-content;
    min-height: 700px;
    padding: 10rem 0 7rem;
  }

  .container {
    @include respond(laptop) {
      padding: 5rem 15rem 0;
    }

    @include respond(tab-port) {
      padding: 8rem 5rem 0;
    }

    @include respond(phone-land) {
      padding: 3rem 5rem 0;
    }
    @include respond(phone) {
      padding: 3rem 2rem 0;
    }
  }

  &__container {
    height: 100%;
    @include flexCenter;
  }

  &__left-container {
    width: 50%;

    @include respond(laptop) {
      width: 100%;
    }

    @include respond(tab-land) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      text-align: center;
    }

    .vimeo-player {
      @include respond(tab-land) {
        margin: 0 auto;
      }
    }

    .button,
    .button:hover {
      @include respond(laptop) {
        margin: 5rem 50% 0;
        transform: translateX(-50%);
      }
    }
    .button {
      @include respond(laptop) {
        transform: translateX(-50%);
      }
    }

    .button:hover {
      @include respond(laptop) {
        transform: translate(-50%, -3px);
      }
    }
  }

  &__title {
    color: white;
    font-size: 5rem;
    margin-right: 5rem;

    @include respond(laptop) {
      font-size: 4rem;
      margin-right: 0;
      text-align: center;
    }

    @include respond(tab-land) {
      font-size: 3.5rem;
    }

    @include respond(phone-land) {
      font-size: 2.8rem;
      padding: 0 5rem;
    }

    @include respond(phone) {
      padding: 0;
      font-size: 2.5rem;
    }
  }
}
