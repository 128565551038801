@import "../abstracts.scss";

.card {
  //padding: 10rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 55rem;
  background-color: white;

  @include respond(tab-land) {
    height: 50rem;
  }

  @include respond(phone-land) {
    height: max-content;
    padding-bottom: 2rem;
  }

  @include respond(phone) {
    width: 85%;
    margin: 0 auto;
  }

  &__image-container {
    position: relative;
  }

  &__play {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    width: 15%;

    &:hover {
      @include hoverPlayIcon;
    }
  }

  &__name {
    font-weight: 700;
    padding: 2rem;

    @include respond(tab-land) {
      padding: 1rem;
    }
  }

  &__img {
    width: 100%;
  }

  &__description {
    padding: 0 4rem;
    text-align: left;

    @include respond(tab-land) {
      padding: 0 3rem;
    }
  }
}
