@import "../abstracts";

.navigation {
  position: absolute;

  &__nav {
    display: flex;
    flex-direction: row;
    width: 100vw;
  }

  &__ul {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;

    & > :last-child {
      padding-right: 0;
    }
  }

  &__item {
    padding: 3rem 2.5rem;
    color: white;
    font-size: 1.8rem;
    font-weight: 300;
    height: min-content;

    &:hover {
      transform: scale(1.2);
      transition: transform 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  &__logo > a > img {
    @include respond(4k) {
      width: 30rem;
    }

    @include respond(laptop) {
      width: 25rem;
    }

    @include respond(vertical-800) {
      width: 25rem;
    }
    @include respond(phone-land) {
      width: 18rem;
    }
    @include respond(phone) {
      width: 14rem;
    }
  }

  &__mobile-menu {
    position: absolute;
    right: 50%;
    top: 10px;
    transform: translateX(50%);
    display: flex;
    justify-content: center;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    background-color: black;
    z-index: 100;
    cursor: pointer;

    &--active {
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 5;
      background-color: black;
      width: 100vw;
      height: 100vh;

      li a {
        display: block;
        font-size: 4rem;
        padding: 1.3rem;

        @include respond(phone-land) {
          font-size: 1.6rem;
        }
        @include respond(phone) {
          font-size: 1.8rem;
        }
      }
    }
  }

  &__hamburger {
    //position: fixed;
    z-index: 500;
    width: 40px;

    @include respond(phone) {
      width: 30px;
    }
  }
}
