@import "../abstracts.scss";

.testimonials {
  z-index: 0;
  padding: 5rem 0 10rem;

  @include respond(phone) {
    padding: 5rem 0 5rem;
  }

  .container {
    @include flexCenter;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include respond(tab-land) {
      padding: 0 4rem;
    }
  }

  &__title {
    margin: 5% 0 5rem;

    @include respond(phone) {
      margin: 0 0 5rem;
    }
  }

  &__card-container {
    position: relative;
    width: 80%;
    display: grid;
    justify-content: center;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fill, 40rem);
    grid-gap: 2rem;

    @include respond(laptop) {
      width: 100%;
    }
  }
}
