@import "../abstracts";

.outline-play-icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -90%);
  cursor: pointer;
  width: 10rem;
  height: 10rem;
  transition: all ease-in-out 0.3s;

  &:hover {
    width: 12rem;
    height: 12rem;
  }

  @include respond(laptop) {
    transform: translate(-50%, -50%);
  }

  @include respond(tab-land) {
  }

  @include respond(phone) {
    transform: translate(-50%, -20%);
    width: 6rem;
    height: 6rem;
  }
}
