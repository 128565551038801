$top-background-image: "../static/pictures/top-background-image.png";
$private-offer-image: "../static/pictures/private-offer-background.png";
$color-tertiary: #00b0ff;
$color-tertiary-dark: #007db8;
$background-tertiary: #f2fafc;

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin hoverPlayIcon {
  filter: brightness(75%);
  cursor: pointer;
  transition: all 0.2s ease;
}

@mixin blueCover($image, $color) {
  background-image: linear-gradient(to right, $color, $color), url($image);
  background-size: cover;
}

// 1em = 16px

@mixin respond($breakpoint) {
  @if $breakpoint == 4k {
    @media only screen and (min-width: 3000px) {
      @content;
    }
  }

  @if $breakpoint == vertical-800 {
    @media only screen and (max-height: 50em) {
      @content;
    }
  }

  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } // 600px
  }

  @if $breakpoint == phone-land {
    @media only screen and (max-width: 46.875em) {
      @content;
    } // 700px
  }

  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } // 900px
  }

  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } // 1200px
  }

  @if $breakpoint == laptop {
    @media only screen and (max-width: 103.12em) {
      @content;
    } // 1650px
  }

  @if $breakpoint == tabport-up {
    @media only screen and (min-width: 56.25em) {
      @content;
    } // 900px
  }

  @if $breakpoint == tabland-up {
    @media only screen and (min-width: 75em) {
      @content;
    } // 1200px
  }

  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 103.12em) {
      @content;
    } // 1650px
  }

  @if $breakpoint == safari {
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        @content;
      }
    }
  }
}
