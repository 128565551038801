@import "../abstracts.scss";
.done-for-you {
  position: relative;

  @include respond(vertical-800) {
    height: min-content;
  }

  @include respond(laptop) {
    padding-bottom: 3rem;
  }

  .button {
    @include respond(phone-land) {
      width: max-content !important;
    }
  }

  &__circle {
    position: absolute;
    z-index: -1;

    transform: translate(8rem, -11rem);
    width: 1400px;

    @include respond(laptop) {
      transform: translate(0, -13rem);
      width: 1100px;
    }
    @include respond(tab-land) {
      transform: translate(0, -11rem);
      width: 800px;
    }
    @include respond(tab-port) {
      transform: translate(0, -8rem);
      width: 600px;
    }
    @include respond(phone) {
      transform: translate(0, -10rem);
      width: 500px;
    }
  }

  &__rect {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--color-secondary);
    transform: translateX(-60px) translateY(-50px);
    height: 300px;
    z-index: 0;

    @include respond(laptop) {
      height: 200px;
      width: 200px;
      transform: translateX(-80px) translateY(-150px);
    }

    @include respond(tab-land) {
      height: 180px;
      width: 180px;
      transform: translateX(-80px) translateY(50px);
    }

    @include respond(phone-land) {
      height: 150px;
      width: 150px;
      transform: translateX(-70px) translateY(0);
    }

    @include respond(phone) {
      height: 100px;
      width: 100px;
      transform: translateX(20px) translateY(-105px);
    }

    rect {
      width: 100px;
      height: 300px;
    }
  }
  .container {
    @include flexCenter;
    flex-direction: column;
  }

  &__title {
    padding-top: 4rem;
    padding-bottom: 2rem;

    @include respond(phone) {
      padding-top: 0;
    }
  }

  &__text {
    width: 60%;
    padding: 4rem 0 3rem;
    z-index: 1;

    @include respond(tab-land) {
      width: 100%;
    }
  }
}
