@import "../abstracts";

.pricing {
  position: relative;
  height: auto;
  padding-bottom: 5rem;

  @include respond(laptop) {
    padding-top: 0;
  }

  @include respond(tab-land) {
    padding-bottom: 5rem;
  }

  &__cards-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include respond(laptop) {
      padding-top: 2rem;
    }
  }

  .container {
    @include respond(laptop) {
      padding: 0 3rem 5rem;
    }

    @include respond(tab-land) {
      padding: 0 1rem 8rem;
    }
  }

  &__title {
    text-align: center;
    padding-bottom: 13rem;

    @include respond(laptop) {
      padding-bottom: 10rem;
    }

    @include respond(tab-land) {
      padding-bottom: 2rem;
    }

    @include respond(phone-land) {
      font-size: 3rem;
    }
  }
}
